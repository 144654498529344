<template>
  <div v-if="view === 'horizontal'">
    <v-table
      class="rounded-none"
      :header-class="headerClass"
    >
      <template #header>
        <v-table-header>Ice Maker</v-table-header>
        <v-table-header>Remote Control</v-table-header>
        <v-table-header class="nowrap">
          Vends <span class="hidden lg:inline-flex">since Off</span>
        </v-table-header>
        <v-table-header>Override</v-table-header>
      </template>

      <v-table-row
        v-for="(maker, index) in makers"
        :key="index"
        :class="{ 'text-gray-400': isDisabled(maker) && isRemoteControlDisabled(maker) }"
      >
        <v-table-col>Ice Maker {{ index+1 }}</v-table-col>
        <v-table-col>
          <ice-maker-button
            v-model="state.form[`${maker}_running`]"
            :disabled="isRemoteControlDisabled(maker)"
            :is-loading="state.savingRunning[maker]"
            @change="saveRunning(maker)"
          />
        </v-table-col>
        <v-table-col :class="{ 'text-gray-400': isDisabled(maker) }">
          {{ state.form[`${maker}_vends_since_off`] }}
        </v-table-col>
        <v-table-col
          class="w-5/12"
          :class="{ 'text-gray-400': isDisabled(maker) }"
        >
          <div class="flex flex-row gap-x-2 items-center">
            <template v-if="!isDisabled(maker)">
              <toggle
                v-model="state.form.override[maker].enabled"
                @change="saveOverride()"
              />
              <span>Override if Vends exceed</span>
              <text-input
                v-model="state.form.override[maker].override_at_vend_count"
                placeholder="Count"
                class="w-24"
                @input="inputChanged"
              />
            </template>
            <template v-else>
              <span>Override if Vends exceed</span>
              <strong>{{ state.form.override[maker].enabled ? 'Enabled' : 'Disabled' }}</strong>
              <strong>{{ state.form.override[maker].override_at_vend_count }}</strong>
            </template>
          </div>
        </v-table-col>
      </v-table-row>
    </v-table>
  </div>
  <div
    v-else
    class="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4"
  >
    <!-- Ice Maker 1 -->
    <div
      v-for="(maker, index) in makers"
      :key="index"
      :class="{ 'w-full md:w-1/2': true, 'text-gray-400': isDisabled(maker) && isRemoteControlDisabled(maker) }"
    >
      <div class="bg-white rounded-md overflow-hidden divide-y">
        <div class="px-4 py-1 bg-big-stone bg-opacity-90">
          <h4 class="font-semibold text-white">
            Ice Maker {{ index+1 }}
          </h4>
        </div>
        <div class="flex items-center">
          <div class="w-1/3 p-2">
            <h4 class="font-semibold">
              Remote Control
            </h4>
          </div>
          <div class="p-2">
            <ice-maker-button
              v-model="state.form[`${maker}_running`]"
              :disabled="isRemoteControlDisabled(maker)"
              :is-loading="state.savingRunning[maker]"
              @change="saveRunning(maker)"
            />
          </div>
        </div>
        <div
          class="flex items-center"
          :class="{ 'text-gray-400': isDisabled(maker) }"
        >
          <div class="w-1/3 p-2">
            <h5 class="font-semibold">
              Vends Since Off
            </h5>
          </div>
          <div class="p-2 font-semibold">
            {{ state.form[`${maker}_vends_since_off`] }}
          </div>
        </div>
        <div
          class="flex items-center"
          :class="{ 'text-gray-400': isDisabled(maker) }"
        >
          <div class="w-1/3 p-2">
            <h5 class="font-semibold">
              Override
            </h5>
          </div>
          <div class="p-2 flex items-center text-sm space-x-2">
            <template v-if="!isDisabled(maker)">
              <toggle
                v-model="state.form.override[maker].enabled"
                @change="saveOverride()"
              />
              <span class="leading-tight">Override if Vends exceed</span>
              <text-input
                v-model="state.form.override[maker].override_at_vend_count"
                placeholder="Count"
                class="w-20 flex-shrink-0"
                @input="inputChanged"
              />
            </template>
            <template v-else>
              <span class="leading-tight">Override if Vends exceed</span>
              <strong>{{ state.form.override[maker].enabled ? 'Enabled' : 'Disabled' }}</strong>
              <strong>{{ state.form.override[maker].override_at_vend_count }}</strong>
            </template>
          </div>
        </div>

        <div
          v-if="state.form.bin_logic[maker].configured"
          :class="{ 'text-gray-400': isDisabled(maker) }"
        >
          <div class="px-4 py-1.5 bg-big-stone bg-opacity-50 w-full flex items-center justify-between">
            <h4 class="font-semibold text-white">
              Bin Logic
            </h4>
            <div v-if="state.form.bin_logic[maker].flash_code_updated_ts" class="flex items-center space-x-2 text-sm">
              <label class="text-gray-100 font-medium">Last update:</label>
              <span class="text-white font-semibold">{{ formatDate(state.form.bin_logic[maker].flash_code_updated_ts) }}</span>
              <!-- <toggle v-model="state.isTimestampVisible" @change="saveTimestampState" class="flex" /> -->
            </div>
          </div>

          <div class="p-1.5 grid grid-cols-2 gap-1.5 text-base">
            <div class="text-center bg-gray-50">
              Flash Code: <strong>{{ state.form.bin_logic[maker].flash_code || 'N/A' }}</strong>
            </div>
            <div class="text-center bg-gray-50">
              Switch State: <strong>{{ state.form.bin_logic[maker].switch || 'N/A' }}</strong>
            </div>
          </div>

          <div class="p-2.5">
            <h6 class="text-sm uppercase font-bold">
              Inputs
            </h6>
            <div class="grid grid-cols-2 lg:grid-cols-4 gap-2 mt-2">
              <div
                v-for="(dict, key) in state.form.bin_logic[maker].inputs"
                :key="key"
              >
                <div class="flex items-center text-sm font-semibold">
                  <div class="led led-orange flex-shrink-0" :data-plcstatus="dict && dict.value" />
                  <h5 class="truncate">
                    {{ formatKey(key) }}
                  </h5>
                </div>
                <!-- <div class="bg-concrete text-xs mt-0.5 p-0.5" v-if="state.isTimestampVisible && dict && dict.timestamp">
                  {{ formatDate(dict.timestamp) }}
                </div>
                <div class="bg-concrete text-xs mt-0.5 p-0.5" v-else>
                  <span>n/a</span>
                </div> -->
              </div>
            </div>

            <h6 class="text-sm uppercase font-bold mt-4">
              Outputs
            </h6>
            <div class="grid grid-cols-2 lg:grid-cols-4 gap-2 mt-2">
              <div
                v-for="(dict, key) in state.form.bin_logic[maker].outputs"
                :key="key"
              >
                <div class="flex items-center text-sm font-semibold">
                  <div class="led flex-shrink-0" :data-plcstatus="dict && dict.value" />
                  <h5 class="truncate">
                    {{ formatKey(key) }}
                  </h5>
                </div>
                <!-- <div class="bg-concrete text-xs mt-0.5 p-0.5" v-if="state.isTimestampVisible && dict && dict.timestamp">
                  {{ formatDate(dict.timestamp) }}
                </div>
                <div class="bg-concrete text-xs mt-0.5 p-0.5" v-else>
                  <span>n/a</span>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, watch } from 'vue'
import http from '@/services/http.js'
import notification from '@/services/notifications.js'
import date from '@/helpers/date.js'

import Toggle from '@/components/form/Toggle.vue'
import IceMakerButton from '@/components/schedules/IceMakerButton.vue'
import TextInput from '@/components/form/Text.vue'
import VTable from '@/components/Table.vue'
import VTableHeader from '@/components/TableHeader.vue'
import VTableRow from '@/components/TableRow.vue'
import VTableCol from '@/components/TableCol.vue'

export default {
  components: {
    TextInput,
    VTable,
    VTableHeader,
    VTableRow,
    VTableCol,
    Toggle,
    IceMakerButton,
  },

  props: {
    disabled: {
      default: false
    },
    makersState: {
      required: true
    },
    makers: {
      default: () => (['im_1', 'im_2']),
    },
    headerClass: {},
    view: {
      default: 'horizontal'
    },
  },

  setup(props) {
    const timestampLocalStorageKey = 'machine_detail_show_binlogic_timestamp'
    // const timestampInitialState = localStorage.getItem(timestampLocalStorageKey)

    const state = reactive({
      overrideTimeout: null,
      savingRunningDelay: null,
      isTimestampVisible: false, //timestampInitialState === 'yes',
      form: props.makersState,
      savingRunning: {
        im_1: false,
        im_2: false,
      }
    })

    const saveOverride = async () => {
      try {
        // const values = {
        //   im_1: {
        //     override_at_vend_count: 100,
        //     enabled: true
        //   },
        //   im_2: {
        //     override_at_vend_count: 50,
        //     enabled: false
        //   }
        // }
        const values = state.form.override
        await http.post(`machine/${state.form.location_id}/schedule/override`, values)
        notification.success('Success', 'Override settings were changed')
      } catch (e) {
        if (e.response) {
          notification.error(e.response.data.Message)
        } else {
          notification.error('Something went wrong. Please, try again.')
        }
      }
      clearOverrideTimeout()
    }

    const saveRunning = async (maker) => {
      state.savingRunning[maker] = true
      const newState = state.form[`${maker}_running`]
      const actionId = state.form[`${maker}_action_id`]
      try {
        const values = {
          action: newState ? `start_${actionId}` : `stop_${actionId}`
        }
        await http.post(`machine/${state.form.location_id}/action`, values)
        notification.success('Success', 'Status was changed')
      } catch (e) {
        state.form[`${maker}_running`] = !newState
        if (e.response) {
          notification.error(e.response.data.Message)
        } else {
          notification.error('Something went wrong. Please, try again.')
        }
      }
      state.savingRunning[maker] = false
    }

    const isDisabled = (maker) => {
      return props.disabled || !state.form.can_edit || state.form[`${maker}_running`] === null
    }

    const isRemoteControlDisabled = (maker) => {
      return props.disabled || state.form[`${maker}_running`] === null
    }

    const clearOverrideTimeout = () => {
      clearTimeout(state.overrideTimeout)
      state.overrideTimeout = null
    }

    const inputChanged = () => {
      clearOverrideTimeout()
      state.overrideTimeout = setTimeout(saveOverride, 1000)
    }

    const formatKey = (key) => {
      // Replace underscores with spaces and capitalize each word
      return key.replace(/_/g, ' ').replace(/\b\w/g, firstChar => firstChar.toUpperCase());
    }

    const formatDate = (timestamp) => {
      return date.logFormat(timestamp, true)
    }

    const saveTimestampState = (value) => {
      localStorage.setItem(timestampLocalStorageKey, value ? 'yes' : 'no')
    }

    watch(
      () => props.makersState,
      () => {
        // skipping value override during override saving and running saving
        if (!state.overrideTimeout && Object.values(state.savingRunning).every(v => v === false)) {
          state.form = props.makersState
        }
      }
    )

    return {
      state,
      saveRunning,
      saveOverride,
      isDisabled,
      isRemoteControlDisabled,
      inputChanged,
      formatKey,
      formatDate,
      saveTimestampState,
    }
  }
}
</script>
